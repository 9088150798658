// extracted by mini-css-extract-plugin
export var animatedTextHover = "video-navigation-module--animated-text-hover--0759b";
export var backgroundAnimation = "video-navigation-module--backgroundAnimation--57d47";
export var buttonNext = "video-navigation-module--button-next--5a729";
export var buttonPrev = "video-navigation-module--button-prev--8c711";
export var errorBlink = "video-navigation-module--error-blink--0f969";
export var icon = "video-navigation-module--icon--a1b7f";
export var linkIcon = "video-navigation-module--link-icon--19a07";
export var linkText = "video-navigation-module--link-text--92091";
export var recipeLink = "video-navigation-module--recipe-link--8ea7e";
export var videoNavigation = "video-navigation-module--video-navigation--3191a";
export var videoNavigationWrapper = "video-navigation-module--video-navigation-wrapper--293d9";