import React from 'react';

import { button } from './angle-button.module.scss';
import AngleIcon from '../../assets/images/svg/angle.svg';
import { IUseButton, useButton } from '../../hooks/use-button';
import { InteractiveElement } from '../../models/interactive-element.model';

interface IAngleButtonProps extends Omit<IUseButton, 'as'> {
    as?: InteractiveElement;
    className?: string;
    rotate?: number;
    ariaLabel?: string;
    disabled?: boolean;
}

const AngleButton = React.forwardRef<HTMLButtonElement, IAngleButtonProps>((props, ref) => {
    const {
        as = 'button',
        to = '',
        className = '',
        rotate = 0,
        onClick = () => {},
        ariaLabel = '',
        disabled = false,
        params,
    } = props;
    const { ButtonTag, buttonProps } = useButton({ as, to, params, onClick });
    return (
        <ButtonTag
            className={`${button} ${className}`}
            aria-label={ariaLabel}
            {...(!ref ? buttonProps : '')}
            ref={ref}
            disabled={disabled}
        >
            <AngleIcon style={{ transform: `rotate(${rotate}deg)` }} />
        </ButtonTag>
    );
});

export default AngleButton;
