import React from 'react';
import Link from '../../../plugins/gatsby-plugin-ap-i18next/src/link';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    videoNavigation,
    videoNavigationWrapper,
    recipeLink,
    animatedTextHover,
    linkText,
    linkIcon,
    buttonPrev,
    buttonNext,
    icon,
} from './video-navigation.module.scss';

import DrinkIcon from '../../assets/images/svg/drink.svg';
import AngleIcon from '../../assets/images/svg/angle.svg';

import Button from '../atoms/button';

interface IVideoNavigation {
    className?: string;
    previousSlug?: string;
    nextSlug?: string;
    recipeSlug?: string;
}

export default function VideoNavigation({
    className,
    previousSlug,
    nextSlug,
    recipeSlug,
}: IVideoNavigation) {
    const { t } = useI18next();

    return (
        <div className={`${videoNavigationWrapper} ${className}`}>
            {recipeSlug && (
                <Link className={recipeLink} to={recipeSlug}>
                    <DrinkIcon className={linkIcon} />
                    <span className={`${linkText} ${animatedTextHover}`}>{t('video.navigation.see')}</span>
                </Link>
            )}
            <div className={videoNavigation}>
                <Button className={buttonNext} to={nextSlug} as="link" disabled={!nextSlug}>
                    {t('video.navigation.next')}
                    <AngleIcon className={icon} />
                </Button>
                <Button
                    className={buttonPrev}
                    variant="text"
                    to={previousSlug}
                    as="link"
                    disabled={!previousSlug}
                >
                    <AngleIcon className={icon} />
                    {t('video.navigation.prev')}
                </Button>
            </div>
        </div>
    );
}
