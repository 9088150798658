import React from 'react';
import { graphql } from 'gatsby';
import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    videoPage,
    contentWrapper,
    contentHeader,
    categoryLabel,
    contentTitle,
    contentDescription,
    videoRatio,
    videoPlayer,
    dateString,
    videoNavigation,
    vlogSection,
} from './video-page.module.scss';
import { IVideo } from '../models/video.model';
import { IQueryAllResult } from '../models/query-all-result.model';
import { IRecipeCategory } from '../models/recipe-category.model';
import { IBreadcrumbsProps } from '../models/breadcrumbs.model';
import { getNodes } from '../utils/get-nodes';
import prependStrapiUrl from '../utils/prepend-strapi-url';

import MainLayout from '../layouts/main-layout';
import Title from '../components/atoms/title';
import VideoPlayer from '../components/atoms/video-player';
import VideoNavigation from '../components/molecules/video-navigation';
import VlogSection from '../components/organisms/vlog-section';
import CategoryLabel from '../components/atoms/category-label';

interface IVideoPageProps {
    data: {
        video: IVideo;
        previousVideo: IQueryAllResult<Pick<IVideo, 'slug'>>;
        nextVideo: IQueryAllResult<Pick<IVideo, 'slug'>>;
        category: IRecipeCategory;
        pageBreadcrumbs: {
            breadcrumbs: IBreadcrumbsProps[];
        };
    };
}

export default function VideoPage({ data }: IVideoPageProps) {
    const { title, description, videoFile, published_at, relatedVideos, videoThumbnail } =
        data.video;
    const { language } = useI18next();
    const { t } = useI18next();

    const previousVideo = getNodes(data.previousVideo)[0];
    const nextVideo = getNodes(data.nextVideo)[0];
    const recipeCategory = data.category;

    const meta = { title, description };
    const page = { meta };

    const videoUrl = prependStrapiUrl(videoFile.url);
    const videoThumbnailSrc = prependStrapiUrl(videoThumbnail.url);

    return (
        <MainLayout
            page={page}
            showRecipesSlider={false}
            className={videoPage}
            includeHeaderPadding={true}
            showLocalizationBar={true}
            breadcrumbs={data.pageBreadcrumbs.breadcrumbs}
        >
            <div className={contentWrapper}>
                <div className={contentHeader}>
                    <Title className={contentTitle}>{title}</Title>
                    {recipeCategory && (
                        <CategoryLabel className={categoryLabel} category={recipeCategory} />
                    )}
                </div>
                <p className={contentDescription}>{description}</p>
                <VideoPlayer
                    url={videoUrl}
                    light={videoThumbnailSrc}
                    ratioClassName={videoRatio}
                    className={videoPlayer}
                />
                <p className={dateString}>{formatDate(language, published_at)}</p>
                <VideoNavigation
                    className={videoNavigation}
                    nextSlug={nextVideo?.slug}
                    previousSlug={previousVideo?.slug}
                    recipeSlug={data.video.recipe?.slug}
                />
                {relatedVideos && relatedVideos.length > 0 && (
                    <VlogSection
                        className={vlogSection}
                        title={t('video.page.related.title')}
                        videos={relatedVideos}
                    />
                )}
            </div>
        </MainLayout>
    );
}

const formatDate = (locale: string, date: string) => {
    return new Intl.DateTimeFormat(locale).format(new Date(date));
};

export const query = graphql`
    query ($language: String!, $videoId: Int!, $recipeId: Int, $path: String, $published_at: Date!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ...localeFields
                }
            }
        }

        video: strapiVideo(videoId: { eq: $videoId }, locale: { eq: $language }) {
            ...videoFields
        }

        pageBreadcrumbs: sitePage(path: { eq: $path }) {
            breadcrumbs {
                label
                slug
            }
        }

        nextVideo: allStrapiVideo(
            filter: { published_at: { lt: $published_at }, locale: { eq: $language } }
            sort: { fields: published_at, order: DESC }
            limit: 1
        ) {
            edges {
                node {
                    slug
                }
            }
        }

        previousVideo: allStrapiVideo(
            filter: { published_at: { gt: $published_at }, locale: { eq: $language } }
            sort: { fields: published_at, order: ASC }
            limit: 1
        ) {
            edges {
                node {
                    slug
                }
            }
        }

        category: strapiRecipeCategory(
            locale: { eq: $language }
            recipes: { elemMatch: { id: { eq: $recipeId } } }
            isRoot: { ne: true }
        ) {
            slug
            recipeCategoryId
            title
            icon {
                url
                caption
                alternativeText
            }
        }
    }
`;
